import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  ListItemText,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionMultiCheckboxFilter = ({
  name,
  dataList,
  selectedCategories,
  setSelectedCategories,
  selectedSectors,
  setSelectedSectors,
}) => {
  const dispatch = useDispatch();

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    console.log("Current Category from Redux :::", selectedCategories);
  }, [dispatch, selectedCategories]);

  useEffect(() => {
    const allSelected = dataList.every((item) =>
      selectedCategories.includes(item)
    );
    setSelectAll(allSelected);

    if (allSelected) {
      if (!selectedSectors.includes(name)) {
        setSelectedSectors((prevSectors) => [...prevSectors, name]);
      }
    } else {
      setSelectedSectors((prevSectors) =>
        prevSectors.filter((sector) => sector !== name)
      );
    }
  }, [selectedCategories, dataList, name, setSelectedSectors, selectedSectors]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCategories(
        selectedCategories.filter((item) => !dataList.includes(item))
      );
      setSelectedSectors(selectedSectors.filter((sector) => sector !== name));
    } else {
      const newSelection = [
        ...selectedCategories,
        ...dataList.filter((item) => !selectedCategories.includes(item)),
      ];
      setSelectedCategories(newSelection);
      if (!selectedSectors.includes(name)) {
        setSelectedSectors([...selectedSectors, name]);
      }
    }
    setSelectAll(!selectAll);
  };

  const handleCheckbox = (option) => {
    const newSelectedCategories = selectedCategories.includes(option)
      ? selectedCategories.filter((cat) => cat !== option)
      : [...selectedCategories, option];

    setSelectedCategories(newSelectedCategories);
    if (newSelectedCategories.length < dataList.length) {
      setSelectAll(false);
      setSelectedSectors(selectedSectors.filter((sector) => sector !== name));
    } else if (newSelectedCategories.length === dataList.length) {
      setSelectAll(true);
      if (!selectedSectors.includes(name)) {
        setSelectedSectors([...selectedSectors, name]);
      }
    }
  };

  return (
    <Accordion sx={{ border: "none", boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {name}
      </AccordionSummary>
      <AccordionDetails>
        <MenuItem
          className="multi-select-checkbox-list"
          onClick={handleSelectAll}
        >
          <Checkbox
            checked={selectAll}
            style={{ padding: "0px", color: "#2280ef" }}
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {dataList.map((item) => (
          <MenuItem
            key={item}
            className="multi-select-checkbox-list"
            onClick={() => handleCheckbox(item)}
          >
            <Checkbox
              checked={selectedCategories.includes(item)}
              style={{ padding: "0px", color: "#2280ef" }}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMultiCheckboxFilter;
