import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Popover,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInitiativeFinancialData,
  fetchInitiativeOverviewData,
} from "../../../redux/reducers/InitiativeSlice";
import { CONSTANTS } from "../../../data/constants";

const CustomMultiSelect = ({
  options,
  selectedOptions,
  setSelectedOptions,
  name,
  icon,
  isAllSelected,
  setIsAllSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const currentYear = CONSTANTS?.currentYear;
  const dispatch = useDispatch();
  const {
    tablePostData,
    overviewPaginationModel,
    sortModel,
    currentDriver,
    currentLever,
    currentSector,
    isAllDriver,
    isAllLever,
    isAllSector,
    currentTab,
    selectedGPCurrentYearMin,
    selectedGPCurrentYearMax,
    selectedGPNextYearMin,
    selectedGPNextYearMax,
    selectedGPYearAfterNextMin,
    selectedGPYearAfterNextMax,
    selectedInitiative,
    selectedOwner,
    selectedSecondOwner,
    selectedFinancialOwner,
    selectedComplexity,
    selectedStatus,
  } = useSelector((state) => state.InitiativeSlice);

  useEffect(() => {}, [
    dispatch,
    tablePostData,
    overviewPaginationModel,
    sortModel,
  ]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (option) => {
    let newSelectedOptions;
    if (option === "All") {
      if (isAllSelected) {
        newSelectedOptions = [];
        dispatch(setIsAllSelected(false));
      } else {
        newSelectedOptions = options;
        dispatch(setIsAllSelected(true));
      }
    } else {
      if (selectedOptions.includes(option)) {
        newSelectedOptions = selectedOptions.filter((item) => item !== option);
        dispatch(setIsAllSelected(false));
      } else if (isAllSelected) {
        newSelectedOptions = options.filter((item) => item !== option);
        dispatch(setIsAllSelected(false));
      } else {
        newSelectedOptions = [...selectedOptions, option];
        if (newSelectedOptions.length === options.length) {
          dispatch(setIsAllSelected(true));
        }
      }
    }
    dispatch(setSelectedOptions(newSelectedOptions));
  };

  const handleApply = () => {
    let filterObj = {
      sector: !isAllSector ? currentSector.length && currentSector : [],
      lever: !isAllLever ? currentLever.length && currentLever : [],
      driver: !isAllDriver ? currentDriver.length && currentDriver : [],
      [`gp_${currentYear}_min`]:
        selectedGPCurrentYearMin > 0 ? selectedGPCurrentYearMin : null,
      [`gp_${currentYear}_max`]:
        selectedGPCurrentYearMax > 0 ? selectedGPCurrentYearMax : null,
      [`gp_${currentYear + 1}_min`]:
        selectedGPNextYearMin > 0 ? selectedGPNextYearMin : null,
      [`gp_${currentYear + 1}_max`]:
        selectedGPNextYearMax > 0 ? selectedGPNextYearMax : null,
      [`gp_${currentYear + 2}_min`]:
        selectedGPYearAfterNextMin > 0 ? selectedGPYearAfterNextMin : null,
      [`gp_${currentYear + 2}_max`]:
        selectedGPYearAfterNextMax > 0 ? selectedGPYearAfterNextMax : null,
      initiative: selectedInitiative,
      owner: selectedOwner,
      delegate: selectedSecondOwner,
      financial_owner: selectedFinancialOwner,
      status: selectedStatus,
      complexity: selectedComplexity,
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      sortBy: `gp_${currentYear}`,
      direction: "desc",
      filters: filterObj,
    };

    if (currentTab == "initiative-overview") {
      dispatch(fetchInitiativeOverviewData(filterData));
    }
    if (currentTab == "imm-financial") {
      dispatch(fetchInitiativeFinancialData(filterData));
    }
    handleClose();
  };

  const open = Boolean(anchorEl);

  useEffect(() => {}, [tablePostData]);

  const handleClearFilter = () => {
    let newSelectedOptions;
    if (isAllSelected) {
      newSelectedOptions = [];
      dispatch(setIsAllSelected(false));
    } else {
      newSelectedOptions = options;
      dispatch(setIsAllSelected(true));
    }
    dispatch(setSelectedOptions(newSelectedOptions));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={!anchorEl ? handleOpen : null}
    >
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "50%",
          height: "28px",
          minWidth: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        {icon}
      </div>
      <FormControl
        sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
        size="small"
      >
        <p style={{ margin: 0 }}>{name}</p>
        <Select
          labelId="custom-multi-select-label"
          id="custom-multi-select"
          variant="standard"
          multiple
          value={isAllSelected ? "All" : selectedOptions}
          renderValue={(selected) => {
            if (isAllSelected) {
              return "All";
            }
            return selected.join(", ");
          }}
          open={false}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiSelect-standard:after": {
              borderBottom: "none",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              minWidth: "230px",
              maxWidth: "max-content",
              marginLeft: "-10px",
              marginTop: "5px",
              padding: "10px",
              borderRadius: "12px",
              maxHeight: "300px",
              overflowY: "auto",
              boxShadow: "0px 2px 5px 0px #dae8ff",
            },
          }}
        >
          <div>
            <MenuItem
              key="all"
              value="All"
              onClick={() => handleCheckboxChange("All")}
            >
              <Checkbox
                checked={isAllSelected}
                style={{ padding: 0, color: "#2280ef" }}
              />
              <ListItemText primary="All" />
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option}
                value={option}
                onClick={() => handleCheckboxChange(option)}
              >
                <Checkbox
                  checked={
                    isAllSelected ? true : selectedOptions.indexOf(option) > -1
                  }
                  style={{ padding: 0, color: "#2280ef" }}
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
            <div
              style={{
                display: "flex",
                padding: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={handleClearFilter}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Clear
              </button>
              <button
                onClick={handleApply}
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  background: "#2280EF",
                  color: "#fff",
                  border: "1px solid #2280EF",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </Popover>
      </FormControl>
    </div>
  );
};

export default CustomMultiSelect;
