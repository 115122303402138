import React, { useEffect, useState } from "react";
import { ArrowDropDown, ArrowDropUp, SortSharp } from "@mui/icons-material";
import { CustomDataGrid } from "./components/CustomDataGrid";
import {
  GridFooter,
  GridFooterContainer,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInitiativeOverviewData,
  handleOverviewPageNo,
  handleOverviewPagination,
  handlePageLimitChange,
  handleSortModel,
} from "../../redux/reducers/InitiativeSlice";
import "../../styles/table.styles.scss";
import { CONSTANTS } from "../../data/constants";

const InititativeTable = ({
  rows,
  columns,
  total,
  columnGroup,
  status,
  rowCount,
}) => {
  const dispatch = useDispatch();
  const currentYear = CONSTANTS?.currentYear;
  const { currentTab, sortModel, overviewPaginationModel, overviewTableData } =
    useSelector((state) => state.InitiativeSlice);
  const [datagridSortModel, setDatagridSortModel] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });

  console.log("pagination Model", paginationModel);

  const handleSortModelChange = (newSortModel) => {
    dispatch(handleSortModel(newSortModel));
    let postData = {
      pageNo: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      sortBy: newSortModel.field,
      direction: newSortModel.sort,
    };
    // if (newSortModel.lenght == 0) {
    //   dispatch(
    //     handleOverviewPagination({ ...overviewPaginationModel, page: 0 })
    //   );
    // }
    // dispatch(fetchInitiativeOverviewData(postData));
  };

  const handlePaginationModel = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    dispatch(handleOverviewPagination(newPaginationModel));
    let postData = {
      pageNo: newPaginationModel.page + 1,
      limit: newPaginationModel.pageSize,
      sortBy: sortModel.field,
      direction: sortModel.sort,
    };
    // dispatch(fetchInitiativeOverviewData(postData));
  };

  console.log("Sort Model :::", sortModel);

  useEffect(() => {
    if (columns) {
      setDatagridSortModel(sortModel);
    }
  }, [sortModel, columns]);

  const columnGroupingModelForFinancial = [
    {
      groupId: "Total",
      headerName: "Total",
      description: "",
      children: [
        { field: "total_gm" },
        { field: "total_gm_sector" },
        { field: "total_gp" },
        { field: "total_nsv" },
      ],
    },
    {
      groupId: currentYear,
      headerName: `${currentYear}`,
      description: "",
      children: [
        { field: `nsv_${currentYear}` },
        // { field: "2024NSV YTG" },
        { field: `gp_${currentYear}` },
        { field: `inc_gm_sector_${currentYear}` },
        // { field: "2024GP($) YTG" },
        { field: `inc_gm_total_${currentYear}` },
      ],
    },
    {
      groupId: `${currentYear + 1}`,
      headerName: `${currentYear + 1}`,
      description: "",
      children: [
        { field: `nsv_${currentYear + 1}` },
        { field: `gp_${currentYear + 1}` },
        { field: `inc_gm_sector_${currentYear + 1}` },
        { field: `inc_gm_total_${currentYear + 1}` },
      ],
    },
    {
      groupId: `${currentYear + 2}`,
      headerName: `${currentYear + 2}`,
      description: "",
      children: [
        { field: `nsv_${currentYear + 2}` },
        { field: `gp_${currentYear + 2}` },
        { field: `inc_gm_sector_${currentYear + 2}` },
        { field: `inc_gm_total_${currentYear + 2}` },
      ],
    },
    // {
    //   groupId: "Basic info",
    //   children: [
    //     {
    //       groupId: "Full name",
    //       children: [{ field: "lastName" }, { field: "firstName" }],
    //     },
    //     { field: "age" },
    //   ],
    // },
  ];
  const columnGroupingModelForExtended = [
    {
      groupId: "2024",
      headerName: "2024",
      description: "",
      children: [
        { field: "2024NSV($)" },
        { field: "2024GP($)" },
        { field: "2024GM%" },
      ],
    },
    {
      groupId: "2025",
      headerName: "2025",
      description: "",
      children: [
        { field: "2025NSV($)" },
        { field: "2025GP($)" },
        { field: "2025GM%" },
      ],
    },
    {
      groupId: "2026",
      headerName: "2026",
      description: "",
      children: [
        { field: "2026NSV($)" },
        { field: "2026GP($)" },
        { field: "2026GM%" },
      ],
    },
  ];

  const renderFooter = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px",
      }}
    >
      <div>{`Total Rows:`}</div>
      <div>{`Total Age: ${30}`}</div>
    </div>
  );

  useEffect(() => {}, [columnGroup, rows, columns]);

  const columnVisibilityModel = React.useMemo(() => {
    // if (userType === UserType.Admin) {
    //   return {
    //     quantity: true,
    //     filledQuantity: true,
    //     id: true,
    //   };
    // }
    return {
      sector: currentTab === "initiative-overview" ? true : false,
      inv_type: false,
      cal_year: false,
      uid: false,
      [`${currentYear}NSV YTG`]: false,
      [`${currentYear}GP($) YTG`]: false,
    };
  }, []);

  // const footerData = rows.pop();

  // console.log("Column", columns);
  // console.log("Column Footer", total);

  // function CustomToolbar() {
  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
  //     </GridToolbarContainer>
  //   );
  // }

  const isValid = (val) => {
    return typeof val === "number" || typeof val === "string";
  };

  const CustomFooter = () => {
    return (
      <>
        {rows.length > 0 &&
          columns.length > 0 &&
          currentTab === "initiative-overview" && (
            <div
              style={{
                background: "#F0F4FB",
                fontWeight: 600,
                padding: "15px 0",
                marginLeft: "55px",
                // marginLeft: currentTab === "initiative-overview" ? "55px" : "100px",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                {columns.map((column) => (
                  <div
                    key={column.field}
                    style={{
                      flex: isValid(rows?.[rows?.length - 1]?.[column?.field])
                        ? column.flex
                        : 0,
                      // padding: "10px",
                      overflow: "clip",
                    }}
                  >
                    {rows?.[rows?.length - 1]?.[column?.field] &&
                    !isNaN(rows?.[rows?.length - 1]?.[column?.field]) &&
                    !isNaN(
                      parseFloat(rows?.[rows?.length - 1]?.[column?.field])
                    )
                      ? Intl.NumberFormat("de-DE", {
                          style: "decimal",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(rows?.[rows?.length - 1]?.[column?.field])
                      : rows?.[rows?.length - 1]?.[column?.field]}
                  </div>
                ))}
              </div>
            </div>
          )}
        <GridFooter style={{ background: "none" }} />
      </>
    );
  };

  // if (status === "loading") {
  //   return (
  //     <div>
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    <CustomDataGrid
      // rows={rows}
      rows={rows?.slice(0, rows?.length - 1)}
      columns={columns}
      checkboxSelection
      loading={status === "loading"}
      // columnHeaderHeight={40}
      autoHeight
      autoWidth
      // getRowId={(row, index) => `${row.src_id}_${index}`}
      getRowId={(row) => row.uid}
      disableColumnFilter
      disableColumnResize
      disableColumnMenu
      disableRowSelectionOnClick
      // showCellVerticalBorder
      // showColumnVerticalBorder
      sortModel={sortModel && datagridSortModel}
      onSortModelChange={(model) => handleSortModelChange(model)}
      columnVisibilityModel={columnVisibilityModel}
      slots={{
        columnSortedDescendingIcon: () => (
          <ArrowDropDown color="success" titleAccess="desc" />
        ),
        columnSortedAscendingIcon: () => (
          <ArrowDropUp color="success" titleAccess="asc" />
        ),
        columnUnsortedIcon: () => <SortSharp color="success" />,
        footer: CustomFooter,
      }}
      sx={{
        "& .MuiDataGrid-cell MuiDataGrid-cellEmpty": {
          display: "none",
        },
        minHeight: "350px",
      }}
      paginationMode="server"
      rowCount={rowCount}
      paginationModel={overviewPaginationModel}
      onPaginationModelChange={handlePaginationModel}
      rowsPerPageOptions={[5, 10]}
      columnGroupingModel={
        columnGroup !== "financial" ? [] : columnGroupingModelForFinancial
      }
      pageSizeOptions={[5, 10]}
    />
  );
};

export default InititativeTable;
