import React, { useEffect, useRef, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomAlert from "../../components/CustomAlert";

const reportId = process.env.REACT_APP_MARGIN_MAP_REPORT_ID;
const groupId = process.env.REACT_APP_MARGIN_MAP_GROUP_ID;
const embedUrl =
  "https://app.powerbi.com/groups/c5b94f6b-789d-4428-8337-936384c7844c/reports/e278acd4-3f06-44e7-a5ea-23b0d7d15906/5235aa4c436940a391f0?experience=power-bi";

const MarginMapPBIReport = () => {
  // const { overviewTableData, financialTableData } = useSelector(
  //   (state) => state.InitiativeSlice
  // );

  const [PBItokens, setPBItokens] = useState({});
  const [filters, setFilters] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const embedRef = useRef(null);

  const embedConfig = {
    type: "report",
    id: reportId,
    embedUrl: PBItokens?.embedUrl,
    accessToken: PBItokens?.embedToken,
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  };

  const updateSlicers = (filters) => {
    console.log("Start Update Filter");
    console.log("ref Data", embedRef);
    if (embedRef.current) {
      const report = embedRef.current;
      console.log("Report from Ref 27 ::", report);

      // report.on("loaded", () => {
      console.log("Report Status 000", report);

      report.getPages().then((pages) => {
        let activePage = pages[0];
        // Alternatively, find the active page:
        // let activePage = pages.filter(page => page.isActive)[0];

        activePage
          .getFilters()
          .then((res) => {
            console.log("Report Status 111", res);
          })
          .catch((err) => {
            console.log("Report Status 222", err);
          });

        activePage.getVisuals().then((visuals) => {
          console.log("@Report Status load", activePage, visuals);
          let slicer = visuals.filter((visual) => visual.type === "slicer");

          slicer.forEach((item, index) => {
            console.log("Report Status--02", item);
            item
              .getSlicerState()
              .then((res) => {
                console.log("Report Status--03", res, filters);
                filters.map((filter) => {
                  console.log("Report Status each Filter :", filter);
                  if (res && res.targets[0].column === filter.target.column) {
                    let tempFilter = {};
                    if (filter.values.length === 0) {
                      tempFilter.filters = [];
                      tempFilter.targets = res.targets[0];
                    } else {
                      tempFilter.filters = [filter];
                      tempFilter.targets = res.targets[0];
                    }
                    item
                      .setSlicerState(tempFilter)
                      .then((res) => {
                        console.log("Report Status--03 after Slice", res);
                      })
                      .catch((err) => {
                        console.log("Report Status--04", err);
                      });
                    return filter;
                  }
                });
              })
              .catch((err) => {
                console.log("Report Status--04", err);
              });
          });
        });
      });
      // });
    }
    console.log("End Update Filter");
  };

  useEffect(() => {
    const categorySector = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "YourTableName",
        column: "SECTOR",
      },
      operator: "In",
      values: ["Adult Care"],
      filterType: models.FilterType.Basic,
    };

    updateSlicers([categorySector]);
    // setFilters([categorySector]);
  }, []);

  useEffect(() => {
    const fetchPBIToken = async () => {
      try {
        const idToken = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/power-bi/token`,
          {
            reportId: reportId,
            groupId: groupId,
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.data.data;
        console.log("PBI token resp ::", data);
        setPBItokens(data);
      } catch (error) {
        console.error("Error while fetching PBI token...", error);
        setAlertMessage(error?.message);
        setAlertType("error");
        setAlertVisible(true);
      }
    };

    fetchPBIToken();
  }, []);

  return (
    <>
    <div className="pbi-chart">
      <PowerBIEmbed
        cssClassName={"reportClass"}
        embedConfig={embedConfig}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
          embedRef.current = embeddedReport;
          console.log("Embeded Report :::", embeddedReport);
        }}
      />
    </div>
    <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </>
  );
};

export default MarginMapPBIReport;
