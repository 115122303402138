import React, { useEffect, useRef, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomAlert from "../../components/CustomAlert";

const reportId = process.env.REACT_APP_SCORECARD_REPORT_ID;
const groupId = process.env.REACT_APP_SCORECARD_GROUP_ID;
const embedUrl =
  "https://app.powerbi.com/groups/c5b94f6b-789d-4428-8337-936384c7844c/reports/97da6cab-ae67-42a9-a077-09646320e6ba/41d74d7b47addda9ec8e?experience=power-bi";

const ScorecardPBI = () => {
  const [PBItokens, setPBItokens] = useState({});
  const [filters, setFilters] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const { currentCategorySector, currentMarket, currentCategory } = useSelector(
    (state) => state.ScoreCardSlice
  );
  const embedRef = useRef(null);

  const embedConfig = {
    type: "report",
    id: reportId,
    embedUrl: PBItokens?.embedUrl,
    accessToken: PBItokens?.embedToken,
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  };

  const updateSlicers = (filters) => {
    console.log("Start Update Filter");
    console.log("ref Data", embedRef);
    if (embedRef.current) {
      const report = embedRef.current;
      console.log("Report from Ref 27 ::", report);

      // report.on("loaded", () => {
      console.log("Report Status 000", report);

      report.getPages().then((pages) => {
        let activePage = pages[0];
        // Alternatively, find the active page:
        // let activePage = pages.filter(page => page.isActive)[0];

        activePage
          .getFilters()
          .then((res) => {
            console.log("Report Status 111", res);
          })
          .catch((err) => {
            console.log("Report Status 222", err);
          });

        activePage.getVisuals().then((visuals) => {
          console.log("@Report Status load", activePage, visuals);
          let slicer = visuals.filter((visual) => visual.type === "slicer");

          slicer.forEach((item, index) => {
            console.log("Report Status--02", item);
            item
              .getSlicerState()
              .then((res) => {
                console.log("Report Status--03", res, filters);
                filters.map((filter) => {
                  console.log("Report Status each Filter :", filter);
                  if (res && res.targets[0].column === filter.target.column) {
                    let tempFilter = {};
                    if (filter.values.length === 0) {
                      tempFilter.filters = [];
                      tempFilter.targets = res.targets[0];
                    } else {
                      tempFilter.filters = [filter];
                      tempFilter.targets = res.targets[0];
                    }
                    
                    console.log("Setting tempFilter:", tempFilter);
                    console.log("res targets" ,res.targets[0]);
                    console.log("filter target", filter.target)
                    item
                      .setSlicerState(tempFilter)
                      .then((res) => {
                        console.log("Report Status--03 after Slice", res);
                      })
                      .catch((err) => {
                        console.log("Report Status--041", err);
                      });
                    return filter;
                  }else{
                    console.log("else");
                  }
                });
              })
              .catch((err) => {
                console.log("Report Status--042", err);
              });
          });
        });
      });
      // });
    }
    console.log("End Update Filter");
  };

  useEffect(() => {
    const fetchPBIToken = async () => {
      try {
        const idToken = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/power-bi/token`,
          {
            reportId: reportId,
            groupId: groupId,
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.data.data;
        console.log("PBI token resp ::", data);
        setPBItokens(data);
      } catch (error) {
        console.error("Error while fetching PBI token...", error);
        setAlertMessage(error?.message);
        setAlertType("error");
        setAlertVisible(true);
      }
    };
    fetchPBIToken();
  }, []);

  useEffect(() => {
    console.log(currentCategory, currentCategorySector, "currentCategorySector")
    const categoryFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "IMM_SCORECARD_WITH_CATEGORY",
        column: "CATEGORY",
      },
      operator: "In",
      values: currentCategory,
      filterType: models.FilterType.Basic,
    };
    const sectorFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "IMM_SCORECARD_WITH_CATEGORY",
        column: "SECTOR",
      },
      operator: "In",
      values: currentCategorySector,
      filterType: models.FilterType.Basic,
    };

    const marketFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "IMM_SCORECARD_WITH_CATEGORY",
        column: "COUNTRY",
      },
      operator: "In",
      values: currentMarket === "All" ? [] : [currentMarket],
      filterType: models.FilterType.Basic,
    };
    // setFilters([scorecardFilter]);
    updateSlicers([sectorFilter, marketFilter, categoryFilter]);
  }, [currentCategorySector, currentMarket, currentCategory]);

  return (
    <div className="scorecard-pbi">
      <PowerBIEmbed
        cssClassName={"reportClass"}
        embedConfig={embedConfig}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
          embedRef.current = embeddedReport;
          console.log("Embeded Report :::", embeddedReport);
        }}
      />
        <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </div>
  );
};

export default ScorecardPBI;
