import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Popover,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchScoreCardData } from "../../../redux/reducers/ScoreCardSlice";

const SingleSelect = ({
  options,
  selectedOptions,
  setSelectedOptions,
  name,
  icon,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { currentSector, currentMarket, currentCategory } = useSelector(
    (state) => state.ScoreCardSlice
  );
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {}, [dispatch]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearFilter = () => {
    dispatch(setSelectedOptions("All"));
    handleClose();
  };

  const handleApply = () => {
    let filters = {
      filters: {
        sector: currentSector == "All" ? [] : currentSector,
        market: currentMarket == "All" ? [] : [currentMarket],
        category: currentCategory == "All" ? [] : currentCategory
      },
    };
    dispatch(fetchScoreCardData(filters));
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={!disabled && !anchorEl ? handleOpen : null}
    >
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "50%",
          height: "28px",
          minWidth: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        {icon}
      </div>
      <FormControl
        sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
        size="small"
      >
        <p style={{ margin: 0 }}>{name}</p>
        <Select
          labelId="custom-multi-select-label"
          id="custom-multi-select"
          variant="standard"
          //   multiple
          value={selectedOptions}
          renderValue={(selected) => {
            return selected;
          }}
          //   renderValue={(selected) => {
          //     // if (selectedOptions.length === options.length) {
          //     if (isAllSelected) {
          //       return "All";
          //     }
          //     return selected.join(", ");
          //   }}
          // onClick={handleOpen}
          open={false} // Prevent default menu from opening
          sx={{
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiSelect-standard:after": {
              borderBottom: "none",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              minWidth: "230px",
              maxWidth: "max-content",
              marginLeft: "-10px",
              marginTop: "5px",
              padding: "10px",
              borderRadius: "12px",
              maxHeight: "300px",
              overflowY: "auto",
              boxShadow: "0px 2px 5px 0px #dae8ff",
            },
          }}
        >
          <div>
            <MenuItem
              key="all"
              value="All"
              //   onClick={(e) => handleCheckboxChange("All")}
              onClick={(e) => dispatch(setSelectedOptions("All"))}
            >
              {/* <Checkbox
                checked={isAllSelected}
                style={{ padding: 0, color: "#2280ef" }}
              /> */}
              <ListItemText primary="All" />
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option}
                value={option}
                // onClick={() => handleCheckboxChange(option)}
                onClick={(e) => dispatch(setSelectedOptions(option))}
              >
                {/* <Checkbox
                  checked={
                    isAllSelected ? true : selectedOptions.indexOf(option) > -1
                  }
                  style={{ padding: 0, color: "#2280ef" }}
                /> */}
                <ListItemText primary={option} />
              </MenuItem>
            ))}
            <div
              style={{
                display: "flex",
                padding: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={handleClearFilter}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Clear
              </button>
              <button
                onClick={handleApply}
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  background: "#2280EF",
                  color: "#fff",
                  border: "1px solid #2280EF",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </Popover>
      </FormControl>
    </div>
  );
};

export default SingleSelect;
